<template>
    <Search @click="$router.push('/search')" :isShowBtn="false"/>
    <div class="nav">
      <span @click="tabChange(0)" :class="{'acitve': sortType==0}">综合</span>
      <span @click="tabChange(1)" :class="{'acitve': sortType==1}">名称</span>
      <span @click="tabChange(2)" :class="{'acitve': sortType==2}">销量</span>
      <span @click="tabChange(3)" :class="{'acitve': sortType==3}">新品</span>
    </div>
    <div class="goodslist">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :immediate-check = "false"
        finished-text="没有更多了"
        @load="pageLoad"
        v-if="dataList.length > 0"
      >
        <GoodsItem @click="goGoodsDetail(item)" @addCar="addCar" v-for="(item,index) in dataList" :key="item.id" :goodsItem="item" :userId="userId" :index="index"/>
      </van-list>
      <ContentNo :contentTips="contentTips" v-if="dataList.length==0"/>
    </div>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, config } from '../../units/request.js'
import GoodsItem from '../../components/GoodsItem.vue'
import Search from '../../components/Search.vue'
import ContentNo from '../../components/ContentNo.vue'
export default {
  components: { GoodsItem, ContentNo, Search },
  name: 'GoodsList',
  data () {
    return {
      dataList: [],
      cateId: '', // 分类Id
      searchKey: '', // 搜索内容
      sortFlag: 1, // 0升序 1降序
      sortType: 0, // 0 综合 1 名称 2 销量 3 新品
      pageNum: 1,
      pageSize: 6,
      finished: false,
      loading: false,
      isLastPage: 0,
      contentTips: '暂无内容'
    }
  },
  methods: {
    // tab切换
    tabChange (index) {
      this.sortType = index
      this.dataList = []
      this.pageNum = 1
      this.finished = false
      this.loading = false
      this.getGoodsList()
    },
    // 根据id获取商品列表
    async getGoodsList () {
      const url = 'v1/goods/user/cityShopGoods.json'
      const param = {
        cat3Id: this.cateId,
        cityId: config.cityId,
        goodsName: this.searchKey,
        sortType: this.sortType,
        sortFlag: this.sortFlag,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userId: this.userId,
        addUserId: this.userId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.dataList.push(...res.data.list)
      this.isLastPage = res.data.isLastPage
      if (this.isLastPage === 1) {
        this.finished = true
      }
      // 获取完数据把Loading状态改成false
      this.loading = false
    },
    // 分页
    pageLoad () {
      this.loading = true
      this.pageNum++
      this.getGoodsList()
    },
    // 进商品详情
    goGoodsDetail (item) {
      this.$router.push({
        path: '/goodsdetail',
        query: {
          shopId: item.shopId,
          id: item.id
        }
      })
    },
    // 添加商品到购物车
    async addCar (model, index) {
      let cartGoodsAmount = 0
      var url = 'v1/cart/saveCart.json'
      var param = {
        shopId: model.shopId,
        userId: this.userId,
        goodsId: model.id,
        number: 1,
        cartId: ''
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      localStorage.carNumNew = res.data.cartNum
      res.data.list.forEach(item => {
        item.goodsList.forEach(itemSub => {
          if (itemSub.goodsId === model.id) {
            cartGoodsAmount = itemSub.goodsNum ? itemSub.goodsNum : item.cartGoodsAmount ? item.cartGoodsAmount : 0
          }
        })
      })
      this.dataList[index].cartGoodsAmount = cartGoodsAmount
      this.$toast(res.data.msg)
    }
  },
  created () {
    this.cateId = this.$route.params.id
    this.userId = localStorage.userId || ''
    this.getGoodsList()
  }
}
</script>

<style lang="scss" scoped>
.search-wrap{display: flex; justify-content: space-between; align-items: center; background:#fff; padding: 0.1rem; align-items: center; color:#333;}
.search-bar{flex:1; display: flex; align-items: center; height: .32rem; background:#f3f3f3; margin-right: .1rem; padding: 0 .1rem; border-radius: .1rem; line-height: .32rem}
.search-bar img{width:.18rem; height: .18rem; margin-right: .1rem}
.search-bar input{flex:1; border:none; background:none}
.search-bar input:focus{outline:none}
.nav{height:.28rem; background:#fff; margin-top: -.05rem; padding: 0 .2rem; display: flex; align-items: center; justify-content: space-around;}
.nav span{display: inline-flex; position: relative; padding:.1rem 0}
.nav span.acitve:after{width: .4rem; height: .02rem; background:#52da5f; content: ''; position:absolute; left:50%; bottom:0.03rem; margin-left:-.2rem; color:#52da5f}
.van-list{display: flex; flex-wrap: wrap;}
</style>

<template>
  <Search @click="$router.push('/search')" :isShowBtn="false"/>
  <div class="content">
    <div class="content-l">
      <div @click="tabChange(item)" :class="{'menu': true, 'active': item.id ==currentId}" v-for="item in firstCategory" :key="item.id">{{item.name}}</div>
      <!-- <div class="menu">蔬菜水果</div> -->
    </div>
    <div class="content-r">
      <div class="banner">
        <img :src="rightBannerUrl"/>
      </div>
      <div class="category-list" v-for="item in categoryList" :key="item.id">
        <h4 v-if="item.list.length>0">{{item.name}}</h4>
        <ul>
          <li @click="$router.push('/goodslist/'+itemSub.id)" v-for="itemSub in item.list" :key="itemSub.id">
            <img :src="itemSub.picUrl"/>
            <span>{{itemSub.name}}</span>
          </li>
        </ul>
      </div>
      <!-- <div class="category-list">
        <h4>蔬菜</h4>
        <ul>
          <li @click="$router.push('/goodslist')">
            <img src="../../images/goods02.jpg"/>
            <span>西红柿</span>
          </li>
          <li @click="$router.push('/goodslist')">
            <img src="../../images/goods02.jpg"/>
            <span>菠菜</span>
          </li>
          <li @click="$router.push('/goodslist')">
            <img src="../../images/goods02.jpg"/>
            <span>豆角</span>
          </li>
          <li @click="$router.push('/goodslist')">
            <img src="../../images/goods02.jpg"/>
            <span>豆角</span>
          </li>
          <li @click="$router.push('/goodslist')">
            <img src="../../images/goods02.jpg"/>
            <span>豆角</span>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
  <Tabbar :carNumNew="carNumNew"/>
</template>
<script>
import qs from 'qs'
import { post, ajaxParam, config } from '../../units/request.js'
import Tabbar from '../home/Tabbar.vue'
import Search from '../../components/Search.vue'
export default {
  components: { Tabbar, Search },
  name: 'Category',
  data () {
    return {
      firstCategory: [],
      categoryList: [],
      rightBannerUrl: '',
      parentId: '',
      currentId: 1
    }
  },
  methods: {
    // 获取一级分类
    async getFirstList () {
      const url = 'v2/goodsCategory/firstList.json'
      const param = {
        cityId: config.cityId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.firstCategory = res.data.list
      this.rightBannerUrl = res.data.list[0].picUrl
      const currentId = res.data.list[0].id
      this.currentId = currentId
      this.getSecondList(currentId)
    },
    // 根据父级id获取商品二级分类
    async getSecondList (parentId) {
      const url = 'v2/goodsCategory/list.json'
      const param = {
        parentId: parentId
      }
      ajaxParam(param)
      const res = await post(url, qs.stringify(param))
      this.categoryList = res.data.list
      this.currentId = parentId
    },
    // 一级分类切换
    tabChange (item) {
      this.getSecondList(item.id)
      this.rightBannerUrl = item.picUrl
    }
  },
  created () {
    this.getFirstList()
    this.carNumNew = localStorage.carNumNew
  }
}
</script>
<style lang="scss" scoped>
.search-wrap{display: flex; justify-content: space-between; align-items: center; background:#fff; padding: 0.1rem; align-items: center; color:#333;}
.search-bar{flex:1; display: flex; align-items: center; height: .32rem; background:#f3f3f3; margin-right: .1rem; padding: 0 .1rem; border-radius: .1rem; line-height: .32rem}
.search-bar img{width:.18rem; height: .18rem; margin-right: .1rem}
.search-bar input{flex:1; border:none; background:none}
.search-bar input:focus{outline:none}
.content{
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right:0;
  bottom: .5rem;
  top: .52rem;
  overflow: auto;
  .content-l{
    width:.9rem;
    background:#fff;
    .menu{
      height: .4rem;
      border-bottom: 1px solid #f3f3f3;
      text-align: center;
      color:#666;
      line-height: .4rem;
      position: relative;
    }
    .active{
      color:#52da5f;
      background:#f3f3f3;
    }
    .active::before{
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: .18rem;
      width: .04rem;
      background-color: #52da5f;
      border-radius: .05rem;
      opacity: .8;
    }
  }
  .content-r{
    flex:1;
    margin-left: .1rem;
    box-sizing: border-box;
    .banner{
      height: .5rem;
      img{width: 100%; height: 100%}
    }
    .category-list{
      h4{
        height: .3rem;
        line-height: .3rem;
        text-align: center;
        }
      ul{
        display: flex;
        flex-wrap: wrap;
        background:#fff;
        li{
          width: 33.3%;
          display: flex;
          padding: .1rem;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          img{
            width:.6rem;
            height: .6rem;
            margin-bottom: .05rem;
            border-radius: .05rem;
          }
        }
      }
    }
  }
}
</style>
